@import 'abstracts';

@mixin custom-dialog {
  .header-info {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
    padding-top: 16px;

    div {
      font-size: 14px;
      font-weight: 600;

      span {
        padding-left: 12px;
        font-weight: normal;
      }

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  .popup-content {
    background: $body-background;
    padding: 20px 50px 32px;
    overflow-y: auto;
    max-height: 500px;
  }

  .popup-actions {
    @include flexCentering;
    background: $white;
    padding-bottom: 16px;
  }

  :host ::ng-deep { /* stylelint-disable-line */
    border-bottom: 1px solid $gray !important; /* stylelint-disable-line declaration-no-important */
    padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */

    body .p-dialog .p-dialog-content {
      background: $white;
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .p-dialog-titlebar-close,
    .p-dialog-header-icons {
      top: 15px;
      right: 15px;
      position: absolute;
    }
  }
}
