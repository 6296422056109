@import 'abstracts';
@import 'components/custom-dialog';

@include custom-dialog;

app-download-logistic-label-popup {
  .popup-content {
    display: flex;
    justify-content: center;
    min-height: 400px;
  }

  cap-button {
    height: 36px;
  }
}
